import './App.css';
import Home from './components/Home';
import AboutMe from './components/AboutMe';
import $ from "jquery";
import { useMediaQuery } from 'react-responsive'


function App() {
  const responsive = useMediaQuery({ query: '(min-width: 1200px)' })

  let content

  // Loading Page
  if ( responsive) {
    $(window).on('load',function(){
      $(".loader").delay(1000).fadeOut(1000);
      $(".content").delay(1000).fadeIn(1000);
    })

    content = 'content'
  } else {
    content = ''
  }

  return (
    <>
    { responsive &&
    <div className='loader'>
      <div></div>
    </div>
    }

    <div id='contentId' className={content}>
      <Home />
      <AboutMe />
    </div>
    </>
  )
}


export default App;
