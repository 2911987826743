import React from 'react'
import style from './AboutMe.module.css'
import { useMediaQuery } from 'react-responsive'
import imageabout from './images/image-aboutme.png'



function AboutMe() {
  const responsive = useMediaQuery({ query: '(min-width: 1200px)' })


  return (
    <div className="container">
      <div className={style.about}>

        {responsive &&
        <img src={imageabout} alt="aboutme" className={style.image}/>
        }
        <div className={style.text}>
          <h2>Sobre mim:</h2>
          <p>Sou um estudante e profissional resiliente e dedicado. De bom humor e de ouvidos abertos para críticas e sugestões. Curioso e sempre querendo saber como as coisas funcionam. Acredito que sempre há uma solução para os desafios, por isso tenho mergulhado na codificação para resolver problemas e concretizar ideias.</p>
          <p>Desenvolvi conhecimento lógico-matemático para solucionar problemas. Gosto de pensar estrategicamente, trabalhar em equipe e planejar.</p>
        </div>
      </div>

      { responsive &&
      <div className={style.arrowRow}>
        <div className={style.arrow}></div>
      </div>
      }
    </div>
  )
}


export default AboutMe
