import React from 'react'
import style from './Home.module.css'
import banner from './images/banner.png'
import { useMediaQuery } from 'react-responsive'
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import img5 from './images/5.png'
import img6 from './images/6.png'
import img7 from './images/7.png'

function Home() {

  document.addEventListener("mousemove", parallax)
  function parallax(e) {
    this.querySelectorAll('.layer').forEach(layer => {
      const speed = layer.getAttribute('data-speed')

      const x = (window.innerWidth - e.pageX*speed) / 250
      const y = (window.innerHeight - e.pageY*speed) / 250

      layer.style.transform = `translateX(${x}px) translateY(${y}px)`
    })
  }

  const responsive = useMediaQuery({ query: '(min-width: 1200px)' })

  return (
    <div className="container">
      <div className={style.banner}>
        <div alt="text">
          <h1>Fabrízio Saullo</h1>
          <p>Full-Stack - Web Developer</p>
        </div>
        { responsive &&
        <section>
          <img src={img1} alt="parallax" data-speed="-5" className="layer" />
          <img src={img2} alt="parallax" data-speed="5" className="layer" />
          <img src={img3} alt="parallax" data-speed="4" className="layer" />
          <img src={img4} alt="parallax" data-speed="3" className="layer" />
          <img src={img5} alt="parallax" data-speed="2" className="layer" />
          <img src={img6} alt="parallax" data-speed="2" className="layer" />
          <img src={img7} alt="parallax" data-speed="-3" className="layer" />
          <div className={style.image} >
            <img src={banner} alt="banner" className='layer' data-speed="1" />
          </div>
        </section>
        }
      </div>
      { responsive &&
      <div className={style.arrowRow}>
        <div className={style.arrow}></div>
      </div>
      }
    </div>
  )
}

export default Home
